<!-- 公司文化 -->
<template>
  <div>
    <div class="header">
      <div>
        <h3>{{ text8 }}</h3>
      </div>
    </div>
    <div class="container">
      <div class="content_nav">
        <router-link to="/about/culture" class="active">{{ text1 }}</router-link>
        <router-link to="/about/glory">{{ text2 }}</router-link>
      </div>
      <div class="content_top">
        <div class="top_left"></div>
        <div class="top_right">
          <div>
            <!-- <h4>Vision</h4> -->
            <h3>{{ text5 }}</h3>
            <p>{{ text6 }}</p>
            <!-- <h5>Focused on tumor immunology, growing into a leading global biopharmaceutical company through continuous innovation in science and technology</h5> -->
          </div>
          <div>
            <!-- <h4>Mission</h4> -->
            <h3>{{ text3 }}</h3>
            <p>{{ text4 }}</p>
            <!-- <h5>Committed to developing innovative biologics for the benefit of human health</h5> -->
          </div>
          <div>
            <!-- <h4>Vision</h4> -->
            <h3>{{ text9 }}</h3>
            <p>{{ text10 }}</p>
            <!-- <h5>Focused on tumor immunology, growing into a leading global biopharmaceutical company through continuous innovation in science and technology</h5> -->
          </div>
        </div>
      </div>
      <div class="content_box">
        <div class="content_bottom">
          <h3>{{ text7 }}</h3>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import { mapState } from "vuex";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "公司文化",
          text2: "公司荣誉",
          text3: "使命",
          text4: "疾病治愈 病人可及",
          text5: "愿景",
          text6: "明日科技 伟大药企",
          text7: "企业价值观",
          text8: "公司文化和荣誉",
          text9: "价值观",
          text10: "药师仁心 智者践行",
        };
        break;
      case "en":
        return {
          text1: "Corporate Culture",
          text2: "Awards",
          text3: "Mission",
          text4: "FutureGen is committed to developing innovative biologics to benefit human health.",
          text5: "Vision",
          text6: "FutureGen focuses on oncology and immunology areas, and hopes to become the globally leading biopharmaceutical company through continuous innovation in science and technology. ",
          text7: "Corporate Values",
          text8: "Corporate Culture and Honors",
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
};
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  height: 400px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/wenhua.png") no-repeat;
  background-size: 100% 100%;
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after {
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
.container {
  width: 100%;
  .content_top {
    width: 1200px;
    margin: 4vh auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top_left {
      width: 50%;
      height: 710px;
      background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/guanyu/wenhua.png") no-repeat;
      background-size: contain;
      background-position: center;
    }
    .top_right {
      width: 40%;
      color: #203864;
      h3 {
        position: relative;
        font-size: 25px;
        margin: 5vh 0 3vh 0;
      }
      h3::after {
        position: absolute;
        top: 137%;
        left: 0;
        content: "";
        width: 342px;
        height: 2px;
        background-color: #ccc;
      }
      p {
        width: 342px;
        font-size: 22px;
        color: #4a4a4a;
      }
      h5 {
        width: 350px;
        font-weight: normal;
        font-size: 14px;
        color: #999999;
        margin-bottom: 100px;
      }
    }
  }
  .content_box {
    background-color: #f3f5f8;
    .content_bottom {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      text-align: center;
      h3 {
        font-size: 50px;
        color: #203864;
        padding-top: 150px;
      }
      div {
        margin-top: 150px;
        margin-left: calc(50% - 465px);
        width: 930px;
        height: 482px;
        background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/wenhua3.png") no-repeat;
        background-size: contain;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
        padding-top: 20%;
      }
      h3::after {
        width: 30px;
      }
    }
  }
  .container {
    .content_nav {
      height: 60px;
      line-height: 60px;
      a {
        height: 60px;
        font-size: 18px;
        margin-right: 15px;
      }
      :nth-child(1) {
        padding-bottom: 17px;
      }
      .line_red::after {
        left: 0%;
        width: 75px;
      }
    }
    .content_top {
      align-items: flex-start;
      margin: 0 auto;
      .top_left {
        display: none;
      }
      .top_right {
        width: 100vw;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        h4 {
          display: none;
        }
        h3::after {
          display: none;
        }
        h5 {
          margin-top: 10px;
          margin-bottom: 100px;
        }
      }
    }
    .content_box {
      .content_bottom {
        height: 350px;
        h3 {
          font-size: 30px;
          padding-top: 34px;
        }
        div {
          margin-top: 82px;
          margin-left: 0;
          width: 100vw;
          height: 240px;
        }
      }
    }
  }
}
</style>
